import { useState } from "react";
import { Box, Button, TextField, Fab } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { addcompany } from "../../components/services/source";
import UseModal from "../../components/useModal";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";

const initialValues = {
  nome: "",
  nif: "",
  inscricaoInss: "",
  morada: "",
  actividadeComercial: "",
  exercicio: "",
};

const companySchema = yup.object().shape({
  nome: yup.string().required("Campo Obrigatório"),
  nif: yup.string().required("Campo Obrigatório"),
  inscricaoInss: yup.string().required("Campo Obrigatório"),
  morada: yup.string().required("Campo Obrigatório"),
  actividadeComercial: yup.string().required("Campo Obrigatório"),
  exercicio: yup.string().required("Campo Obrigatório"),
});

const Company = () => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = async (values) => {
    await addcompany(values).then((res) => {if(res.data){
      setOpen(true);
    }});
  };

  return (
    <Box m="20px">
      <Header title="EMPRESA" subtitle="Cadastro Empresa" />

      <UseModal
        open={open}
        handleClose={handleClose}
        modalContent="Empresa inserido com sucesso"
        closeButton={
          <Box display="flex" justifyContent="end">
            <Fab
              size="small"
              color="secondary"
              aria-label="close"
              onClick={handleClose}
            >
              <CloseSharpIcon />
            </Fab>
          </Box>
        }
      />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={companySchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              m="20px"
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="NOME"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nome}
                name="nome"
                error={!!touched.nome && !!errors.nome}
                helperText={touched.nome && errors.nome}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="NIF"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nif}
                name="nif"
                error={!!touched.nif && !!errors.nif}
                helperText={touched.nif && errors.nif}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="INSCRIÇÃO INSS"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.inscricaoInss}
                name="inscricaoInss"
                error={!!touched.inscricaoInss && !!errors.inscricaoInss}
                helperText={touched.inscricaoInss && errors.inscricaoInss}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="MORADA"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.morada}
                name="morada"
                error={!!touched.morada && !!errors.morada}
                helperText={touched.morada && errors.morada}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="ACTIVIDADE COMERCIAL"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.actividadeComercial}
                name="actividadeComercial"
                error={
                  !!touched.actividadeComercial && !!errors.actividadeComercial
                }
                helperText={
                  touched.actividadeComercial && errors.actividadeComercial
                }
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="EXERCICIO"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.exercicio}
                name="exercicio"
                error={!!touched.exercicio && !!errors.exercicio}
                helperText={touched.exercicio && errors.exercicio}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" m="20px">
              <Button type="submit" color="secondary" variant="contained">
                Criar Empresa
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Company;
