import React from "react";
import auth from "../../components/services/auth";
import { useLocation, Navigate, Outlet } from "react-router-dom";

const userAut = () => {
  const user = auth.getCurrentUser();
  return user;
};

const RequireAuth = () => {
  const location = useLocation();
  const isAuth = userAut();
  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default RequireAuth;
