import React from "react";
import {
  Box,
  Modal,
  Backdrop,
  Fade,
  Typography,
  Button,
  Fab,
} from "@mui/material";
import AddIcon from "@mui/icons-material";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid ",
  boxShadow: 24,
  p: 4,
};

const UseModal = ({ open, handleClose, modalTitle, modalContent, closeButton }) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slotProps={Backdrop}
      slots={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            {modalTitle}
          </Typography>
          <Typography
            id="transition-modal-description"
            sx={{ mt: 2, color: "secondary" }}
          >
            {modalContent}
          </Typography>
          {closeButton}
          {/* <Box display="flex" justifyContent="end">
            <Fab
              size="small"
              color="secondary"
              aria-label="close"
              onClick={handleClose}
            >
              <CloseSharpIcon />
            </Fab>
          </Box> */}
        </Box>
      </Fade>
    </Modal>
  );
};

export default UseModal;
