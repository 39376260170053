// import React, { useState, useEffect, useCallback } from "react";
// import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import AddIcon from "@mui/icons-material/Add";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/DeleteOutlined";
// import SaveIcon from "@mui/icons-material/Save";
// import CancelIcon from "@mui/icons-material/Close";
// import { useTheme } from "@mui/material";
// import { tokens } from "../../theme";
// import {
//   ptBR,
//   GridToolbar,
//   GridRowModes,
//   DataGrid,
//   GridToolbarContainer,
//   GridActionsCellItem,
//   GridRowEditStopReasons,
// } from "@mui/x-data-grid";
// import { randomId } from "@mui/x-data-grid-generator";
// import Header from "../../components/Header";
// import {
//   addValores,
//   removeValores,
//   getValores,
//   getSaldo,
//   addSaldoCaixa,
//   updateSaldoCaixa,
// } from "../../components/services/source";
// import { useLocation } from "react-router-dom";
// import { ArrowLeftIcon } from "@mui/x-date-pickers";
// import { useNavigate } from "react-router-dom";
// import dayjs from "dayjs";

// const initialRows = [];

// function EditToolbar(props) {
//   const { setRows, setRowModesModel } = props;

//   const location = useLocation();
//   const { state } = location;
//   const selectDate = state.selectedDate.$d;

//   const handleClick = () => {
//     const id = randomId();
//     const newRow = {
//       id,
//       cab: "",
//       descricao: "",
//       data: selectDate,
//       solicitante: "",
//       debito: "",
//       credito: "",
//       saldo: "",
//     };
//     setRows((oldRows) => [...oldRows, newRow]);
//     setRowModesModel((oldModel) => ({
//       ...oldModel,
//       [id]: { mode: GridRowModes.Edit, fieldToFocus: "cab" },
//     }));
//   };

//   return (
//     <GridToolbarContainer>
//       <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
//         ADICIONAR
//       </Button>
//       <GridToolbar />
//     </GridToolbarContainer>
//   );
// }

// const FolhaCaixa = () => {
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const [rows, setRows] = useState(initialRows);
//   const [rowModesModel, setRowModesModel] = useState({});
//   const [saldoAnterior, setSaldoAnterior] = useState();
//   const [totals, setTotals] = useState();

//   const location = useLocation();
//   const { state } = location;
//   const selectDate = state.selectedDate.$d;

//   useEffect(() => {
//     async function caixaValores() {
//       const result = await getValores(selectDate);
//       const formattedData = result.data.map((row) => ({
//         ...row,
//         data: new Date(row.data),
//       }));
//       setRows(formattedData);
//     }
//     caixaValores();
//   }, []);

//   useEffect(() => {
//     async function getSaldoAnterior() {
//       const result = await getSaldo(selectDate);
//       setSaldoAnterior(result.data[0]);
//     }
//     getSaldoAnterior();
//   }, [selectDate]);

//   const formatNumber = (value) => {
//     const stringValue = typeof value === "string" ? value : value.toString();
//     const parsedValue = parseFloat(stringValue.replace(/,/g, ""));
//     if (!isNaN(parsedValue) && isFinite(parsedValue)) {
//       return parsedValue.toLocaleString(undefined, {
//         minimumFractionDigits: 2,
//         maximumFractionDigits: 2,
//       });
//     } else {
//       return "";
//     }
//   };

//   const handleRowEditStop = (params, event) => {
//     if (params.reason === GridRowEditStopReasons.rowFocusOut) {
//       event.defaultMuiPrevented = true;
//     }
//   };

//   const navigate = useNavigate();

//   const handleBack = () => {
//     navigate("/caixa");
//   };

//   const handleEditClick = (id) => () => {
//     setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
//   };

//   const handleSaveClick = (id) => () => {
//     setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
//   };

//   const handleDeleteClick = (id) => () => {
//     const rowToDelete = rows.find((row) => row.id === id);
//     if (rowToDelete) {
//       const { data } = rowToDelete;
//       removeValores(id);
//       const updatedRows = rows.filter((row) => row.id !== id);
//       setRows(updatedRows);
//       const newTotals = calculateTotals(updatedRows);
//       setTotals(newTotals);
//       updateSaldoCaixa({ date: data, total: newTotals });
//     }
//   };

//   const handleCancelClick = (id) => () => {
//     setRowModesModel({
//       ...rowModesModel,
//       [id]: { mode: GridRowModes.View, ignoreModifications: true },
//     });

//     const editedRow = rows.find((row) => row.id === id);
//     if (editedRow.isNew) {
//       setRows(rows.filter((row) => row.id !== id));
//     }
//   };

//   const processRowUpdate = (newRow) => {
//     const rowIndex = rows.findIndex((row) => row.id === newRow.id);

//     let previousSaldo = 0;
//     if (rowIndex > 0) {
//       previousSaldo = parseFloat(rows[rowIndex - 1].saldo);
//     }

//     let updatedSaldo = previousSaldo;
//     if (newRow.debito) {
//       updatedSaldo -= parseFloat(newRow.debito);
//     }
//     if (newRow.credito) {
//       updatedSaldo += parseFloat(newRow.credito);
//     }

//     const updatedRow = { ...newRow, saldo: updatedSaldo };

//     const updatedRows = [...rows];
//     updatedRows[rowIndex] = updatedRow;

//     for (let i = rowIndex + 1; i < updatedRows.length; i++) {
//       const currentRow = updatedRows[i];
//       let newSaldo = parseFloat(updatedRows[i - 1].saldo);
//       if (currentRow.debito) {
//         newSaldo -= parseFloat(currentRow.debito);
//       }
//       if (currentRow.credito) {
//         newSaldo += parseFloat(currentRow.credito);
//       }
//       updatedRows[i] = { ...currentRow, saldo: newSaldo };
//     }

//     addValores(updatedRow);
//     addSaldoCaixa(updatedRow, calculateTotals(updatedRows));
//     setRows(updatedRows);
//     return updatedRow;
//   };

//   const handleRowModesModelChange = (newRowModesModel) => {
//     setRowModesModel(newRowModesModel);
//   };

//   const getLastSaldo = () => {
//     if (!saldoAnterior) return "0.00";
//     return saldoAnterior.saldo ? formatNumber(saldoAnterior.saldo) : "0.00";
//   };

//   const calculateTotals = useCallback(
//     (rowsToCalculate = rows) => {
//       if (rowsToCalculate.length === 0) return getLastSaldo();
//       const lastRow = rowsToCalculate[rowsToCalculate.length - 1];
//       const saldoFromRows = lastRow.saldo ? parseFloat(lastRow.saldo) : 0;
//       const initialSaldo = saldoAnterior ? parseFloat(saldoAnterior.saldo) : 0;
//       const totalSaldo = initialSaldo + saldoFromRows;
//       return formatNumber(totalSaldo);
//     },
//     [rows, saldoAnterior]
//   );

//   useEffect(() => {
//     setTotals(calculateTotals());
//   }, [rows, saldoAnterior, calculateTotals]);

//   const columns = [
//     { field: "cab", headerName: "CAB", width: 90, editable: true },
//     {
//       field: "descricao",
//       headerName: "Descrição",
//       width: 300,
//       align: "left",
//       headerAlign: "left",
//       editable: true,
//     },
//     {
//       field: "data",
//       headerName: "Data",
//       type: "date",
//       width: 180,
//       editable: false,
//       valueGetter: (params) => params.value,
//       valueFormatter: (params) => dayjs(params.value).format("DD/MM/YYYY"),
//       renderCell: (params) => {
//         if (params.id === "total") return null;
//         return dayjs(params.value).format("DD/MM/YYYY");
//       },
//     },
//     {
//       field: "solicitante",
//       headerName: "Solicitante",
//       width: 220,
//       editable: true,
//       type: "text",
//     },
//     {
//       field: "debito",
//       headerName: "Debito",
//       width: 150,
//       align: "left",
//       headerAlign: "left",
//       editable: true,
//       type: "number",
//       valueFormatter: (params) =>
//         params.value ? formatNumber(params.value) : "",
//     },
//     {
//       field: "credito",
//       headerName: "Credito",
//       width: 150,
//       align: "left",
//       headerAlign: "left",
//       editable: true,
//       type: "number",
//       valueFormatter: (params) =>
//         params.value ? formatNumber(params.value) : "",
//     },
//     {
//       field: "actions",
//       type: "actions",
//       headerName: "Acção",
//       flex: 0.5,
//       cellClassName: "actions",
//       getActions: ({ id, row }) => {
//         if (id === "total") return [<strong>{row.saldo}</strong>];
//         const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

//         if (isInEditMode) {
//           return [
//             <GridActionsCellItem
//               icon={<SaveIcon />}
//               label="Save"
//               sx={{ color: "green" }}
//               onClick={handleSaveClick(id)}
//             />,
//             <GridActionsCellItem
//               icon={<CancelIcon />}
//               label="Cancel"
//               className="textPrimary"
//               onClick={handleCancelClick(id)}
//               color="inherit"
//             />,
//           ];
//         }

//         return [
//           <GridActionsCellItem
//             icon={<EditIcon />}
//             label="Edit"
//             className="textPrimary"
//             onClick={handleEditClick(id)}
//             color="inherit"
//           />,
//           <GridActionsCellItem
//             icon={<DeleteIcon />}
//             label="Delete"
//             onClick={handleDeleteClick(id)}
//             color="red !important"
//           />,
//         ];
//       },
//     },
//   ];

//   return (
//     <Box m="20px">
//       <Header title="CAIXA" subtitle="Folha de Caixa Diario" />
//       <Box
//         sx={{
//           m: "20px",
//           height: "100%",
//           width: "85%",
//           "& .actions": {
//             color: "text.secondary",
//           },
//           "& .textPrimary": {
//             color: "text.primary",
//           },
//           "& .name-column--cell": {
//             color: colors.greenAccent[300],
//           },
//           "& .MuiDataGrid-columnHeaders": {
//             backgroundColor: colors.blueAccent[700],
//             borderBottom: "none",
//           },
//           "& .MuiDataGrid-virtualScroller": {
//             backgroundColor: colors.primary[400],
//           },
//           "& .MuiDataGrid-footerContainer": {
//             backgroundColor: colors.blueAccent[700],
//             borderTop: "none",
//           },
//           "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
//             color: `${colors.grey[100]} !important`,
//           },
//           "& .MuiDataGrid-row--total": {
//             backgroundColor: colors.blueAccent[700],
//           },
//         }}
//       >
//         <Box sx={{ display: "flex", justifyContent: "space-between" }}>
//           <Box>
//             <Button
//               variant="contained"
//               onClick={handleBack}
//               startIcon={<ArrowLeftIcon />}
//               color="primary"
//             >
//               Voltar
//             </Button>
//           </Box>
//           <Box>
//             <Header subtitle={`Saldo Inicial: ${getLastSaldo()}`} />
//           </Box>
//         </Box>
//         <DataGrid
//           localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
//           rows={[
//             ...rows,
//             {
//               id: "total",
//               cab: "TOTAL",
//               descricao: "",
//               debito: "",
//               credito: "",
//               saldo: "",
//             },
//             {
//               id: "total",
//               cab: "SALDO FINAL",
//               descricao: "",
//               debito: "",
//               credito: "",
//               saldo: totals,
//             },
//           ]}
//           columns={columns}
//           editMode="row"
//           rowModesModel={rowModesModel}
//           onRowModesModelChange={handleRowModesModelChange}
//           onRowEditStop={handleRowEditStop}
//           processRowUpdate={processRowUpdate}
//           slots={{
//             toolbar: EditToolbar,
//           }}
//           slotProps={{
//             toolbar: { setRows, setRowModesModel },
//           }}
//           getRowId={(row) => (row.id === "total" ? "total" : row.id)}
//           getRowClassName={(params) => {
//             if (params.id === "total") return "MuiDataGrid-row--total";
//             return "";
//           }}

//         />
//       </Box>
//     </Box>
//   );
// };

// export default FolhaCaixa;

import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
  ptBR,
  GridToolbar,
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import Header from "../../components/Header";
import {
  addValores,
  removeValores,
  getValores,
  getSaldo,
  addSaldoCaixa,
  updateSaldoCaixa,
} from "../../components/services/source";
import { useLocation } from "react-router-dom";
import { ArrowLeftIcon } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const initialRows = [];

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const location = useLocation();
  const { state } = location;
  const selectDate = state.selectedDate.$d;

  const handleClick = () => {
    const id = randomId();
    const newRow = {
      id,
      cab: "",
      descricao: "",
      data: selectDate,
      solicitante: "",
      debito: "",
      credito: "",
      saldo: "",
    };
    setRows((oldRows) => [...oldRows, newRow]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "cab" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        ADICIONAR
      </Button>
      <GridToolbar />
    </GridToolbarContainer>
  );
}

const FolhaCaixa = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rows, setRows] = useState(initialRows);
  const [rowModesModel, setRowModesModel] = useState({});
  const [saldoAnterior, setSaldoAnterior] = useState();
  const [totals, setTotals] = useState({ debito: 0, credito: 0 });

  const location = useLocation();
  const { state } = location;
  const selectDate = state.selectedDate.$d;

  useEffect(() => {
    async function caixaValores() {
      const result = await getValores(selectDate);
      const formattedData = result.data.map((row) => ({
        ...row,
        data: new Date(row.data),
      }));
      setRows(formattedData);
    }
    caixaValores();
  }, [selectDate]);

  useEffect(() => {
    async function getSaldoAnterior() {
      const result = await getSaldo(selectDate);
      setSaldoAnterior(result.data[0]);
    }
    getSaldoAnterior();
  }, [selectDate]);

  const formatNumber = (value) => {
    const stringValue = typeof value === "string" ? value : value.toString();
    const parsedValue = parseFloat(stringValue.replace(/,/g, ""));
    if (!isNaN(parsedValue) && isFinite(parsedValue)) {
      return parsedValue.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return "";
    }
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/caixa");
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    const rowToDelete = rows.find((row) => row.id === id);
    if (rowToDelete) {
      const { data } = rowToDelete;
      removeValores(id);
      const updatedRows = rows.filter((row) => row.id !== id);
      setRows(updatedRows);
      const newTotals = calculateTotals(updatedRows);
      setTotals(newTotals);
      updateSaldoCaixa({ date: data, total: newTotals });
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const rowIndex = rows.findIndex((row) => row.id === newRow.id);

    let previousSaldo = 0;
    if (rowIndex > 0) {
      previousSaldo = parseFloat(rows[rowIndex - 1].saldo);
    }

    let updatedSaldo = previousSaldo;
    if (newRow.debito) {
      updatedSaldo -= parseFloat(newRow.debito);
    }
    if (newRow.credito) {
      updatedSaldo += parseFloat(newRow.credito);
    }

    const updatedRow = { ...newRow, saldo: updatedSaldo };

    const updatedRows = [...rows];
    updatedRows[rowIndex] = updatedRow;

    for (let i = rowIndex + 1; i < updatedRows.length; i++) {
      const currentRow = updatedRows[i];
      let newSaldo = parseFloat(updatedRows[i - 1].saldo);
      if (currentRow.debito) {
        newSaldo -= parseFloat(currentRow.debito);
      }
      if (currentRow.credito) {
        newSaldo += parseFloat(currentRow.credito);
      }
      updatedRows[i] = { ...currentRow, saldo: newSaldo };
    }
    console.log(updatedRow);
    addValores(updatedRow);
    addSaldoCaixa(updatedRow, calculateTotals(updatedRows));
    setRows(updatedRows);
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const getLastSaldo = () => {
    if (!saldoAnterior) return "0.00";
    return saldoAnterior.saldo ? formatNumber(saldoAnterior.saldo) : "0.00";
  };

  // const calculateTotals = useCallback(
  //   (rowsToCalculate = rows) => {
  //     const totalDebito = rowsToCalculate.reduce(
  //       (sum, row) => sum + (parseFloat(row.debito) || 0),
  //       0
  //     );
  //     const totalCredito = rowsToCalculate.reduce(
  //       (sum, row) => sum + (parseFloat(row.credito) || 0),
  //       0
  //     );
  //     const saldoFromRows =
  //       rowsToCalculate.length > 0
  //         ? parseFloat(rowsToCalculate[rowsToCalculate.length - 1].saldo)
  //         : 0;
  //     const initialSaldo = saldoAnterior ? parseFloat(saldoAnterior.saldo) : 0;
  //     const totalSaldo = initialSaldo + saldoFromRows;
  //     return {
  //       debito: formatNumber(totalDebito),
  //       credito: formatNumber(totalCredito),
  //       saldo: formatNumber(totalSaldo),
  //     };
  //   },
  //   [rows, saldoAnterior]
  // );

  const calculateTotals = useCallback(
    (rowsToCalculate = rows) => {
      const totalDebito = rowsToCalculate.reduce(
              (sum, row) => sum + (parseFloat(row.debito) || 0),
              0
            );
            const totalCredito = rowsToCalculate.reduce(
              (sum, row) => sum + (parseFloat(row.credito) || 0),
              0
            );
      if (rowsToCalculate.length === 0) {
        const initialSaldo = saldoAnterior ? parseFloat(saldoAnterior.saldo.replace(/,/g, '')) : 0;
        return {
          debito: 0,
          credito: 0,
          saldo: formatNumber(initialSaldo),
        };
      }
      
      const lastRow = rowsToCalculate[rowsToCalculate.length - 1];
      const saldoFromRows = lastRow.saldo ? parseFloat(lastRow.saldo) : 0;
      const initialSaldo = saldoAnterior ? parseFloat(saldoAnterior.saldo.replace(/,/g, '')) : 0;
      const totalSaldo = initialSaldo + saldoFromRows;
      return {
        debito: formatNumber(totalDebito),
       credito: formatNumber(totalCredito),
        saldo: formatNumber(totalSaldo),
      };
    },
    [rows, saldoAnterior]
  );
  
  
  

  useEffect(() => {
    setTotals(calculateTotals());
  }, [rows, saldoAnterior, calculateTotals]);

  const columns = [
    { field: "cab", headerName: "CAB", width: 90, editable: true },
    {
      field: "descricao",
      headerName: "Descrição",
      width: 300,
      align: "left",
      headerAlign: "left",
      editable: true,
    },
    {
      field: "data",
      headerName: "Data",
      type: "date",
      width: 180,
      editable: false,
      valueGetter: (params) => params.value,
      valueFormatter: (params) => dayjs(params.value).format("DD/MM/YYYY"),
      renderCell: (params) => {
        if (params.id === "total") return null;
        return dayjs(params.value).format("DD/MM/YYYY");
      },
    },
    {
      field: "solicitante",
      headerName: "Solicitante",
      width: 220,
      editable: true,
      type: "text",
    },
    {
      field: "debito",
      headerName: "Débito",
      width: 150,
      align: "left",
      headerAlign: "left",
      editable: true,
      type: "number",
      valueFormatter: (params) =>
        params.value ? formatNumber(params.value) : "",
    },
    {
      field: "credito",
      headerName: "Crédito",
      width: 150,
      align: "left",
      headerAlign: "left",
      editable: true,
      type: "number",
      valueFormatter: (params) =>
        params.value ? formatNumber(params.value) : "",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Ações",
      flex: 0.5,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        if (id === "total") return [<strong>{row.saldo}</strong>];
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{ color: "green" }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="red !important"
          />,
        ];
      },
    },
  ];

  return (
    <Box m="20px">
      <Header title="CAIXA" subtitle="Folha de Caixa Diario" />
      <Box
        sx={{
          m: "20px",
          height: "100%",
          width: "85%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: colors.blueAccent[700],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
          "& .MuiDataGrid-row--total": {
            backgroundColor: colors.blueAccent[700],
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Button
              variant="contained"
              onClick={handleBack}
              startIcon={<ArrowLeftIcon />}
              color="primary"
            >
              Voltar
            </Button>
          </Box>
          <Box>
            <Header subtitle={`Saldo Inicial: ${getLastSaldo()}`} />
          </Box>
        </Box>
        <DataGrid
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          rows={[
            ...rows,
            {
              id: "total",
              cab: "TOTAL",
              descricao: "",
              debito: totals.debito,
              credito: totals.credito,
              saldo: totals.saldo,
            },
          ]}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          getRowId={(row) => (row.id === "total" ? "total" : row.id)}
          getRowClassName={(params) => {
            if (params.id === "total") return "MuiDataGrid-row--total";
            return "";
          }}
        />
      </Box>
    </Box>
  );
};

export default FolhaCaixa;
