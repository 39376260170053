import http from "./httpServices";

//folhas caixa
export function addValores(data) {
  return http.post("/caixa.php", data);
}

export function removeValores(data) {
  return http.post("/remove_valores_caixa.php", data);
}

export function getValores(data) {
  return http.post("/get_valores_caixa.php", data);
}

export function getSaldo(data){
  return http.post("/get_saldo_anterior.php",data);
}

export function addSaldoCaixa(data, totals){
  return http.post("/add_saldo_caixa.php", {...data, totals});
}

export function updateSaldoCaixa(data){
  return http.post("/update_saldo_caixa.php",data );
}

//Graficos Folha Caixa

export function getYearValues(data){
  return http.post("/get_year_value.php",data);
}

export function getMonthValues(){
  return http.get("/get_month_value.php");
}

//company
export function addcompany(data) {
  return http.post("/add_company.php", data);
}

export function editcompany(data) {
  return http.post("/edit_company.php", data);
}

export function getcompany(data) {
  return http.get("/get_company.php", data);
}

//Login
export function checkMail(data) {
  return http.post("/checkemail.php", data);
}
