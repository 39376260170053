import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Link from "@mui/material/Link";
import auth from "../../components/services/auth";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="http://monteiroteck.com">
        Monteiroteck
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const Login = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = async (values) => {
    try {
      await auth.login(values);
      window.location.href = "./caixa";
    } catch (ex) {
      if (ex.response && ex.response.status === 401) {
        console.log("error");
      }
    }
  };





  return (
    <Box>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid item xs={false} sm={4} md={7} />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          sx={{ backgroundColor: "#071539" }}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              src={process.env.PUBLIC_URL + "/assets/ocinyi.png"}
              alt="logo"
              sx={{
                width: { xs: "300px", sm: "350px", md: "450px" },
                height: "auto",
                mb: 2,
              }}
            />
            <Avatar sx={{ bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" sx={{ mt: "10px" }}>
              Login
            </Typography>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email("Email Invalidos")
                  .required("Campo Obrigatório"),
                password: Yup.string()
                  .min(6, "Must be at least 6 characters")
                  .required("Campo Obrigatório"),
              })}
              onSubmit={handleFormSubmit}
            >
              {({ errors, touched }) => (
                <Form noValidate sx={{ mt: 1 }}>
                  <Field
                    as={TextField}
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="off"
                    autoFocus
                    error={touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                  />
                  <Field
                    as={TextField}
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Palavra Passe"
                    type="password"
                    id="password"
                    error={touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                  />
                  <Button
                    color="secondary"
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Submeter
                  </Button>
                </Form>
              )}
            </Formik>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
