import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { Box, Button, useTheme, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AddIcon from "@mui/icons-material/Add";
import {
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import "./card.css";
import { tokens } from "../../theme.js";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { getYearValues, getMonthValues } from "../../components/services/source.js";

const today = new Date();
const month = today.toLocaleString("pt-PT", { month: "long" });
const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);
const year = today.getFullYear();
const date = today.getDate();
const currentDate = `${date} de ${capitalizedMonth} de ${year}`;

const Caixa = () => {
  const theme = useTheme();
  const today = dayjs();
  const colors = tokens(theme.palette.mode);
  const [activeCard, setActiveCard] = useState(null);
  const [selectedDate, setSelectedDate] = useState(today);
  const [valuesYear, setValuesYear] = useState();
  const [data, setData] = useState([]);
  const [valuesMonth, setValuesMonth] = useState();
  const [data1, setData1] = useState([]);

  useEffect(() => {
    async function getYear() {
      const result = await getYearValues(year);
      setValuesYear(result.data);
    }
    getYear();
  }, []);

  useEffect(() => {
    // Array of month names
    const months = [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ];

    // Initialize data with zero values
    const initialData = months.map((month) => ({
      name: month,
      Credito: 0,
      Debito: 0,
    }));

    // Map valuesYear to the initialData
    valuesYear?.forEach((item) => {
      const index = months.indexOf(item.name);
      if (index !== -1) {
        initialData[index].Credito = item.Credito;
        initialData[index].Debito = item.Debito;
      }
    });

    setData(initialData);
  }, [valuesYear]);

  useEffect(() => {
    async function getMonth() {
      const result = await getMonthValues();
      setValuesMonth(result.data);
    }
    getMonth();
  }, []);

  useEffect(() => {
    async function getMonth() {
      const result = await getMonthValues();
      setValuesMonth(result.data || []);
    }
    getMonth();
  }, []);

  useEffect(() => {
    if (!Array.isArray(valuesMonth) || valuesMonth.length === 0) return;

    const currentMonth = dayjs().month() + 1; // months are 0-based, so add 1
    const currentYear = dayjs().year();
    const daysInMonth = dayjs(`${currentYear}-${currentMonth}`).daysInMonth();

    const defaultData = Array.from({ length: daysInMonth }, (_, index) => ({
      name: (index + 1).toString(),
      debito: 0,
      credito: 0,
    }));

    const updatedData = defaultData.map((day) => {
      const existingData = valuesMonth.find(
        (entry) => entry.name === parseInt(day.name)
      );
      return existingData
        ? { ...day, debito: existingData.debito, credito: existingData.credito }
        : day;
    });

    setData1(updatedData);
  }, [valuesMonth]);

  const navigate = useNavigate();

  const handleCardToggle = (card) => {
    setActiveCard(activeCard === card ? null : card);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleOpen = () => {
    navigate("/folhaCaixa", { state: { selectedDate } });
  };

  return (
    <Box m="20px">
      <Header title="Caixa" subtitle="Página Principal" />
      <Box sx={{ display: "flex", gap: "120px", justifyContent: "center" }}>
        {/* Ano */}
        <div className="card" id="card-visitors">
          <input
            type="checkbox"
            checked={activeCard === "ano"}
            onChange={() => handleCardToggle("ano")}
            id="card-visitors-indicator"
          />
          <div className="header">
            {/* Grafico Ano */}
            <label className="indicator" for="card-visitors-indicator">
              <svg className="open" width="18" height="27">
                <line
                  x1="1.5"
                  y1="12"
                  x2="2"
                  y2="25"
                  stroke-linecap="round"
                  style={{ stroke: "#FFFFFF", strokeWidth: 3 }}
                />
                <line
                  x1="9"
                  y1="7"
                  x2="9"
                  y2="25"
                  stroke-linecap="round"
                  style={{ stroke: "#FFFFFF", strokeWidth: 3 }}
                />
                <line
                  x1="16.5"
                  y1="2"
                  x2="16.5"
                  y2="25"
                  stroke-linecap="round"
                  style={{ stroke: "#FFFFFF", strokeWidth: 3 }}
                />
              </svg>
              <svg className="close" width="15" height="25">
                <line
                  x1="1.5"
                  y1="13.5"
                  x2="15"
                  y2="0"
                  style={{ stroke: "#FFFFFF", strokeWidth: 3 }}
                />
                <line
                  x1="1.5"
                  y1="11.5"
                  x2="15"
                  y2="25"
                  style={{ stroke: "#FFFFFF", strokeWidth: 3 }}
                />
              </svg>
            </label>
            <div className="content">
              <div className="data">
                <div className="top">
                  <p className="title">Ano</p>
                  <p className="date">{year}</p>
                  <div className="year">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100"
                      height="100"
                      fill="currentColor"
                      className="bi bi-calendar-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5h16V4H0V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5" />
                    </svg>
                  </div>
                </div>
                {/* Grafico */}
                {activeCard === "ano" && (
                  <div style={{ marginTop: "100%", marginLeft: "50%" }}>
                    <BarChart
                      width={700}
                      height={400}
                      data={data}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="1 1" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend
                        verticalAlign="top"
                        wrapperStyle={{ lineHeight: "40px" }}
                      />
                      <ReferenceLine y={0} stroke="#000" />
                      <Brush dataKey="name" height={20} stroke="orange" />
                      <Bar dataKey="Credito" fill="#0da0e7" />
                      <Bar dataKey="Debito" fill="#e7787a" />
                    </BarChart>
                  </div>
                )}
              </div>
              {/* Header */}
              <p className="title">Ano</p>
              <p className="date">{year}</p>
              <div className="float"></div>
            </div>
          </div>
          {/* Content */}
          <div className="info">
            <p className="counter">Gráfico Anual</p>
          </div>
        </div>
        {/* Ano */}
        {/* Mes */}
        <div className="card" id="card-mes">
          <input
            type="checkbox"
            id="card-mes-indicator"
            checked={activeCard === "mes"}
            onChange={() => handleCardToggle("mes")}
          />
          <div className="header">
            <label className="indicator" for="card-mes-indicator">
              <svg className="open" width="18" height="27">
                <line
                  x1="1.5"
                  y1="12"
                  x2="2"
                  y2="25"
                  stroke-linecap="round"
                  style={{ stroke: "#FFFFFF", strokeWidth: 3 }}
                />
                <line
                  x1="9"
                  y1="7"
                  x2="9"
                  y2="25"
                  stroke-linecap="round"
                  style={{ stroke: "#FFFFFF", strokeWidth: 3 }}
                />
                <line
                  x1="16.5"
                  y1="2"
                  x2="16.5"
                  y2="25"
                  stroke-linecap="round"
                  style={{ stroke: "#FFFFFF", strokeWidth: 3 }}
                />
              </svg>
              <svg className="close" width="15" height="25">
                <line
                  x1="1.5"
                  y1="13.5"
                  x2="15"
                  y2="0"
                  style={{ stroke: "#FFFFFF", strokeWidth: 3 }}
                />
                <line
                  x1="1.5"
                  y1="11.5"
                  x2="15"
                  y2="25"
                  style={{ stroke: "#FFFFFF", strokeWidth: 3 }}
                />
              </svg>
            </label>
            <div className="content">
              <div className="data">
                <div className="top">
                  <p className="title">Mes</p>
                  <p className="date">{capitalizedMonth}</p>
                  <div className="mes">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100"
                      height="100"
                      fill="currentColor"
                      className="bi bi-calendar-month"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.56 11.332 3.1 9.73h1.984l.54 1.602h.718L4.444 6h-.696L1.85 11.332zm1.544-4.527L4.9 9.18H3.284l.8-2.375zm5.746.422h-.676V9.77c0 .652-.414 1.023-1.004 1.023-.539 0-.98-.246-.98-1.012V7.227h-.676v2.746c0 .941.606 1.425 1.453 1.425.656 0 1.043-.28 1.188-.605h.027v.539h.668zm2.258 5.046c-.563 0-.91-.304-.985-.636h-.687c.094.683.625 1.199 1.668 1.199.93 0 1.746-.527 1.746-1.578V7.227h-.649v.578h-.019c-.191-.348-.637-.64-1.195-.64-.965 0-1.64.679-1.64 1.886v.34c0 1.23.683 1.902 1.64 1.902.558 0 1.008-.293 1.172-.648h.02v.605c0 .645-.423 1.023-1.071 1.023m.008-4.53c.648 0 1.062.527 1.062 1.359v.253c0 .848-.39 1.364-1.062 1.364-.692 0-1.098-.512-1.098-1.364v-.253c0-.868.406-1.36 1.098-1.36z" />
                      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                    </svg>
                  </div>
                </div>
                {/* Grafico */}
                {activeCard === "mes" && (
                  <div style={{ marginTop: "100%", marginLeft: "-80%" }}>
                    <BarChart
                      width={700}
                      height={400}
                      data={data1}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend
                        verticalAlign="top"
                        wrapperStyle={{ lineHeight: "40px" }}
                      />
                      <ReferenceLine y={0} stroke="#000" />
                      <Brush dataKey="name" height={20} stroke="orange" />
                      <Bar dataKey="credito" fill="#0da0e7" />
                      <Bar dataKey="debito" fill="#e7787a" />
                    </BarChart>
                  </div>
                )}
              </div>
              <p className="title">Mes</p>
              <p className="date">{capitalizedMonth}</p>
              <div className="float"></div>
            </div>
          </div>
          <div className="info">
            <p className="counter">Gráfico Mensal</p>
          </div>
        </div>
        {/* Mes */}
        {/* Dia */}
        <div className="card" id="card-dia">
          <input
            type="checkbox"
            id="card-dia-indicator"
            checked={activeCard === "dia"}
            onChange={() => handleCardToggle("dia")}
          />
          <div className="header">
            <label className="indicator" for="card-dia-indicator">
              <AddIcon sx={{ fontSize: 50 }} className="open" />
              <svg className="close" width="15" height="25">
                <line
                  x1="1.5"
                  y1="13.5"
                  x2="15"
                  y2="0"
                  style={{ stroke: "#FFFFFF", strokeWidth: 3 }}
                />
                <line
                  x1="1.5"
                  y1="11.5"
                  x2="15"
                  y2="25"
                  style={{ stroke: "#FFFFFF", strokeWidth: 3 }}
                />
              </svg>
            </label>
            <div className="content">
              <div className="data">
                <div className="top">
                  <p className="title">Hoje</p>
                  <p className="date">{currentDate}</p>
                  <div className="day">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100"
                      height="100"
                      fill="currentColor"
                      className="bi bi-calendar-day"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.684 11.523v-2.3h2.261v-.61H4.684V6.801h2.464v-.61H4v5.332zm3.296 0h.676V8.98c0-.554.227-1.007.953-1.007.125 0 .258.004.329.015v-.613a2 2 0 0 0-.254-.02c-.582 0-.891.32-1.012.567h-.02v-.504H7.98zm2.805-5.093c0 .238.192.425.43.425a.428.428 0 1 0 0-.855.426.426 0 0 0-.43.43m.094 5.093h.672V7.418h-.672z" />
                      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                    </svg>
                  </div>
                </div>
                {/* Grafico */}
                {activeCard === "dia" && (
                  <div style={{ marginTop: "120%", marginLeft: "-195%" }}>
                    <Box
                      sx={{
                        backgroundColor: "#3c594726",
                        width: "582px",
                        height: "291px",
                      }}
                    >
                      <Box
                        sx={{
                          paddingTop: "10%",
                          paddingLeft: "5%",
                          paddingRight: "5%",
                        }}
                      >
                        <Typography
                          variant="h5"
                          color={colors.greenAccent[400]}
                          sx={{ mb: "30px" }}
                        >
                          Selecionar Data
                        </Typography>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="pt"
                        >
                          <DemoContainer
                            components={[
                              "DatePicker",
                              "TimePicker",
                              "DateTimePicker",
                              "DateRangePicker",
                            ]}
                          >
                            <DemoItem>
                              <DatePicker
                                value={selectedDate}
                                onChange={handleDateChange}
                              />
                            </DemoItem>
                          </DemoContainer>
                        </LocalizationProvider>
                        <Button
                          onClick={handleOpen}
                          variant="contained"
                          color="success"
                          sx={{ mt: "50px" }}
                          startIcon={<CalendarTodayIcon />}
                        >
                          Abrir
                        </Button>
                      </Box>
                    </Box>
                  </div>
                )}
              </div>
              <p className="title">Hoje</p>
              <p className="date">{currentDate}</p>
              <div className="float"></div>
            </div>
          </div>
          {/* Content */}
          <div className="info">
            <p className="counter">Folha Caixa</p>
          </div>
        </div>
        {/* Dia */}
      </Box>
    </Box>
  );
};

export default Caixa;
