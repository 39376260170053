import { jwtDecode } from "jwt-decode";
import http from "./httpServices";

const tokenKey = "token";
const user = "/user";

export async function login(data) {
  await http.post("/login.php", data).then((res) => {
    if (res.data !== "false") {
      sessionStorage.setItem(tokenKey, res.data.jwt);
    } else {
      alert("Utilizador ou Palavra Passe Incorrecto!!!");
    }
  });
}

export function logout() {
  sessionStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    const jwt = sessionStorage.getItem(tokenKey);
    return jwtDecode(jwt);
  } catch (error) {
    return null;
  }
}

export function register(data) {
  http
    .post("/create_user.php", data)
    .then((res) => sessionStorage.setItem(tokenKey, res.data.jwt));
}

const auth = {
  login,
  logout,
  getCurrentUser,
  register,
};

export default auth;
