import React, { useState } from "react";
import { Box, TextField, Button, Alert } from "@mui/material";
import Header from "../../components/Header";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { checkMail } from "../../components/services/source";
import auth from "../../components/services/auth";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import AlertTitle from "@mui/material/AlertTitle";

const initialValues = {
  nome: "",
  apelido: "",
  email: "",
  company: "",
  password: "",
};

const registerSchema = yup.object().shape({
  nome: yup.string().required("Campo Obrigatório"),
  apelido: yup.string().required("Campo Obrigatório"),
  email: yup
    .string()
    .required("Campo Obrigatório")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Preencher com Valido Email"
    ),
  company: yup.string().required("Campo Obrigatório"),
  password: yup
    .string()
    .required("Campo Obrigatório")
    .min(8, "Palavra Passe com min 8 caracteres"),
  confirpass: yup
    .string()
    .required("Campo Obrigatório")
    .oneOf([yup.ref("password")], "As palavras passes têm que ser iguais"),
});

const Register = () => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const handelBack = () => {
    navigate("/dashboard");
  };

  const handleFormSubmit = (data) => {
    checkMail(data).then((res) => {
      if (res.data === true) {
        setError(true);
      } else if (res.data === false) {
        auth.register(data);
        setOpen(true);
      }
    });
  };

  return (
    <Box m="20px">
      <Header title="Registar" subtitle="Registo Utilizador" />
      <Collapse in={open}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
                window.location.reload();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          Utilizador Criado com Successo!
        </Alert>
      </Collapse>

      <Collapse in={error}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setError(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          <AlertTitle>O utilizador (emai) ja esta registado!!!</AlertTitle>
        </Alert>
      </Collapse>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={registerSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              m="20px"
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="NOME"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nome}
                name="nome"
                error={!!touched.nome && !!errors.nome}
                helperText={touched.nome && errors.nome}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="APELIDO"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.apelido}
                name="apelido"
                error={!!touched.apelido && !!errors.apelido}
                helperText={touched.apelido && errors.apelido}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="EMAIL"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="EMPRESA"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.company}
                name="company"
                error={!!touched.company && !!errors.company}
                helperText={touched.company && errors.company}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="PALAVRA PASSE"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="password"
                label="CONFIRMAR PALAVRA PASSE"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.confirpass}
                name="confirpass"
                error={!!touched.confirpass && !!errors.confirpass}
                helperText={touched.confirpass && errors.confirpass}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            {!open ? (
              <Box display="flex" justifyContent="space-between" m="20px">
                <Button
                  type="button"
                  color="secondary"
                  variant="contained"
                  onClick={handelBack}
                >
                  Voltar
                </Button>
                <Button type="submit" color="secondary" variant="contained">
                  Criar Utilizador
                </Button>
              </Box>
            ) : null}
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Register;
