import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Sidebar from "./scenes/global/Sidebar";
import Topbar from "./scenes/global/Topbar";
//Login
import RequireAuth from "./scenes/global/requireAuth";
import Login from "./scenes/login";
import Dashboard from "./scenes/dashboard";
import Register from "./scenes/register";
import auth from "./components/services/auth";
//Empresa
import Company from "./scenes/company";
import EditCompany from "./scenes/editcompany";
//Caixa
import Caixa from "./scenes/caixa";
import FolhaCaixa from "./scenes/folhacaixa";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {auth.getCurrentUser() && <Sidebar isSidebar={isSidebar} />}
          <main className="content">
            {auth.getCurrentUser() && <Topbar setIsSidebar={setIsSidebar} />}
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<Login />} />
              {/* Protected Routes */}
              <Route element={<RequireAuth />}>
                <Route path="/register" element={<Register />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/caixa" element={<Caixa />} />
                <Route path="/folhacaixa" element={<FolhaCaixa />} />
                <Route path="/company" element={<Company />} />
                <Route path="/editcompany" element={<EditCompany />} />
              </Route>
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
